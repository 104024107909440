import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mt-32 mb-28 px-2 w-full sm:w-2/3 mx-auto text-center">
      <div className="text-super-large text-yellow">404</div>
      <h1 className="text-3xl uppercase pb-10">Not found</h1>
      <div className="text-base">
        There are places in the world that you shouldn&apos;t visit for your own good!{' '}
        <span role="img" aria-label="super-villain">
          🦹
        </span>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
